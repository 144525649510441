import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutHiddenDocs } from '../lib/helpers'
import ProductPreviewGrid from '../components/product-preview-grid'
import ProductPreviewList from '../components/product-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/content'
import ContentContainer from '../containers/content'

export const query = graphql`
  query MarketIndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    navMenu: sanityNavigationMenu(title: { eq: "Main" }) {
      id
      introText
      items {
        landingPageRoute {
          slug {
            current
            _type
            _key
          }
        }
        link
        route
        title
      }
      footerItems {
        landingPageRoute {
          slug {
            current
            _type
            _key
          }
        }
        link
        route
        overlay
        title
      }
    }
    products: allSanityProduct(
      sort: { fields: [_updatedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          artist
          description
          price
          previewImageSize {
            widthDesktop
          }
          mainImage {
            ...SanityImage
            alt
          }
          _rawExcerpt
          tags {
            _id
            title
          }
          slug {
            current
          }
          isVisible
        }
      }
    }
  }
`

const MarketIndexPage = props => {
  const { data, errors } = props
  const navIntroText = (data.navMenu && data.navMenu.introText) || ''
  const menuItems = data.navMenu && (data.navMenu.items || [])
  const menuFooterItems = data.navMenu && (data.navMenu.footerItems || [])

  if (errors) {
    return (
      <ContentContainer>
        <GraphQLErrorList errors={errors} />
      </ContentContainer>
    )
  }

  const site = (data || {}).site
  const ogImage = site.openGraph.image
  const productNodes = (data || {}).products
    ? mapEdgesToNodes(data.products)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutHiddenDocs)
    : []

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <ContentContainer
      navMenuItems={menuItems}
      navMenuFooterItems={menuFooterItems}
      navMenuTitle="Market"
      navIntroText={navIntroText}
    >
      <SEO
        title="Balkan...Market"
        description={site.description || ''}
        keywords={site.keywords || []}
        image={ogImage}
      />
      <div className="page-container">
        <h1 hidden>{site.title}</h1>
        {/* <div>{productNodes && <ProductPreviewGrid nodes={productNodes} />}</div> */}
        <div>{productNodes && <ProductPreviewList nodes={productNodes} />}</div>
      </div>
    </ContentContainer>
  )
}

export default MarketIndexPage
