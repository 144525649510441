import { format } from 'date-fns'
import { Link, navigate } from 'gatsby'
import React from 'react'
import {
  getCollaborationUrl,
  getFluidProps,
  getPreviewAriaLabel,
  getPreviewImageAltText,
  isBrowser,
  isTopOut,
} from '../lib/helpers'
import Img from 'gatsby-image'
import PortableText from './portableText'
import { nanoid } from 'nanoid'

class CollaborationPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isContainerLinkActive: true,
    }
    this.containerRef = React.createRef()
    this.textInnerRef = React.createRef()
  }

  getImageSize = () => {
    const { previewImageSize, customPreviewImageSize } = this.props
    const main = previewImageSize && previewImageSize.widthDesktop
    const custom = customPreviewImageSize && customPreviewImageSize.widthDesktop
    return custom ? custom : main ? main : 'is-m-landscape'
  }

  render() {
    const {
      title,
      _rawFormattedTitle,
      artist,
      venue,
      location,
      startDate,
      endDate,
      isInList,
      index,
      slug,
    } = this.props
    const { isContainerLinkActive } = this.state
    const image = this.props.rawImageReference ? this.props._rawMainImage : this.props.mainImage
    const imageSize = this.getImageSize()
    const tags = this.props._rawTags ? this.props._rawTags : this.props.tags
    const fluidProps = image && getFluidProps(image, 2400)
    const ariaLabel = getPreviewAriaLabel(title, artist)
    const previewImageAltText = getPreviewImageAltText(title, artist)


    return (
      <div
        className={`collaboration-preview preview ${isInList ? 'is-in-list' : 'is-pb'} ${
          index ? 'is-pb-index-' + index : ''
        }
        ${imageSize ? 'image-sizing--' + imageSize : ''}`}
        ref={this.containerRef}
        aria-label={ariaLabel}
        onClick={isContainerLinkActive ? () => navigate(getCollaborationUrl(slug.current)) : null}
      >
        <div className="conversation-preview__text">
          <div ref={this.textInnerRef}>
            <Link to={getCollaborationUrl(slug.current)} aria-label={ariaLabel}>
              <div className="collaboration-preview__title">
                {title && artist && (
                  <>
                    <span>
                      <em>
                        {_rawFormattedTitle ? (
                          <PortableText blocks={_rawFormattedTitle} />
                        ) : (
                          <>
                            {title}
                            <br />
                          </>
                        )}
                      </em>
                      {artist}
                    </span>
                    <br />
                  </>
                )}
                {venue && (
                  <>
                    <span>{venue}</span>
                    <br />
                  </>
                )}
                {location && (
                  <>
                    <span>{location}</span>
                    <br />
                  </>
                )}
                {startDate && endDate && (
                  <>
                    <span>
                      <time dateTime={startDate}>{format(startDate, 'DD.MM')}</time>–
                      <time dateTime={endDate}>{format(endDate, 'DD.MM.YYYY')}</time>
                    </span>
                  </>
                )}
              </div>
            </Link>
            <div className="collaboration-preview__tags">
              {tags &&
                tags.map((tag, i) => (
                  <div className="btn content-tag content-tag--info" key={nanoid()}>
                    {tag.title}
                  </div>
                ))}
              {!isInList && (
                <Link
                  className="btn content-tag content-tag--link"
                  to="/collaborations"
                  aria-label="Collaborations overview"
                  onMouseEnter={() => this.setState({ isContainerLinkActive: false })}
                  onMouseLeave={() => this.setState({ isContainerLinkActive: true })}
                >
                  Balkan...Collaborations
                </Link>
              )}
            </div>
          </div>
        </div>
        <Link
          className={`collaboration-preview__image ${imageSize}`}
          to={getCollaborationUrl(slug.current)}
          aria-label={ariaLabel}
        >
          {fluidProps && <Img fluid={fluidProps} alt={previewImageAltText} />}
        </Link>
      </div>
    )
  }
}

export default CollaborationPreview
