// import { Link } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'
import ProductPreview from './product-preview'

function ProductPreviewGrid(props) {
  return (
    <div className="product-preview-grid">
      {props.title && <h2>{props.title}</h2>}
      <ul className="columns is-marginless is-multiline">
        {props.nodes &&
          props.nodes.map(node => (
            <li className="column is-full-mobile is-half-tablet is-half-desktop" key={node.id}>
              <ProductPreview {...node} isInGrid />
            </li>
          ))}
      </ul>
    </div>
  )
}

ProductPreview.defaultProps = {
  title: '',
  nodes: [],
}

export default ProductPreviewGrid
