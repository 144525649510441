import React from 'react'
import { Link, navigate } from 'gatsby'
import {
  getProductUrl,
  getFluidProps,
  getPreviewAriaLabel,
  getPreviewImageAltText,
  isBrowser,
  isTopOut,
} from '../lib/helpers'
import Img from 'gatsby-image'
import { nanoid } from 'nanoid'

class ProductPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrolled: false,
      isBottom: false,
      isContainerLinkActive: true,
    }
    this.containerRef = React.createRef()
    this.textInnerRef = React.createRef()
  }

  onScroll = () => {
    const containerEl = this.containerRef.current
    const textInnerEl = this.textInnerRef.current
    if (containerEl && textInnerEl) {
      const textInnerElHeight = textInnerEl.clientHeight + 16
      const containerBottomToTop = containerEl.getBoundingClientRect().bottom
      const textBelowContainer = textInnerElHeight + 16 >= containerBottomToTop
      this.setState({
        isScrolled: isTopOut(containerEl, 16),
        isBottom: textBelowContainer,
      })
    }
  }

  // list view
  getImageSize = () => {
    const { previewImageSize, customPreviewImageSize } = this.props
    const main = previewImageSize && previewImageSize.widthDesktop
    const custom = customPreviewImageSize && customPreviewImageSize.widthDesktop
    return custom ? custom : main ? main : 'is-l-landscape'
  }

  render() {
    const {
      title,
      artist,
      description,
      price,
      previewImageSize,
      isInGrid,
      isInList,
      index,
      slug,
    } = this.props
    const { isScrolled, isBottom, isContainerLinkActive } = this.state
    const image = this.props.rawImageReference ? this.props._rawMainImage : this.props.mainImage
    const imageSize = this.getImageSize()
    const tags = this.props._rawTags ? this.props._rawTags : this.props.tags
    const fluidProps = image && getFluidProps(image, 2400)
    const ariaLabel = getPreviewAriaLabel(title, artist)
    const previewImageAltText = description
      ? `${getPreviewImageAltText(title, artist)}, ${description} `
      : getPreviewImageAltText(title, artist)

    const titleContent = (
      <>
        {title && artist && (
          <>
            <span>
              <em>{title}</em>, {artist}
            </span>
            <br />
          </>
        )}
        {description && (
          <>
            <span>{description}</span>
            <br />
          </>
        )}
        {price && (
          <>
            <span>{price}</span> Euro
            <br />
          </>
        )}
      </>
    )

    if (isInGrid) {
      return (
        <div
          className="product-preview is-in-grid"
          aria-label={ariaLabel}
          onClick={isContainerLinkActive ? () => navigate(getProductUrl(slug.current)) : null}
        >
          <div className="product-preview__image">
            {fluidProps && (
              <Img
                fluid={fluidProps}
                imgStyle={{
                  objectFit: 'contain',
                  objectPosition: '50% 50%',
                }}
                alt={previewImageAltText}
              />
            )}
          </div>
          <div className="product-preview__hover-title">{titleContent}</div>
          <div className="product-preview__mobile-title">
            <div className="product-preview__mobile-title-inner">{titleContent}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`product-preview preview ${!isInList ? 'is-pb' : ''}
          ${index && !isInList ? 'is-pb-index-' + index : ''}
          ${imageSize ? 'image-sizing--' + imageSize : ''}`}
          ref={this.containerRef}
          aria-label={ariaLabel}
          onClick={isContainerLinkActive ? () => navigate(getProductUrl(slug.current)) : null}
        >
          <div
            className={`product-preview__text ${isScrolled ? 'is-container-scrolled' : ''} ${
              isBottom ? 'is-bottom' : ''
            }`}
          >
            <div ref={this.textInnerRef}>
              <Link to={getProductUrl(slug.current)}>
                <div className="product-preview__title">
                  {title && artist && (
                    <>
                      <span>
                        <em>{title}</em>, {artist}
                      </span>
                      <br />
                    </>
                  )}
                  {description && (
                    <>
                      <span>{description}</span>
                      <br />
                    </>
                  )}
                  {price && (
                    <>
                      <span>{price}</span> Euro
                      <br />
                    </>
                  )}
                </div>
              </Link>
              <div className="product-preview__tags">
                {tags &&
                  tags.map(tag => (
                    <div className="btn content-tag content-tag--info" key={nanoid()}>
                      {tag.title}
                    </div>
                  ))}
                <Link
                  to="/market"
                  className="btn content-tag content-tag--link"
                  aria-label="Market overview"
                  onMouseEnter={() => this.setState({ isContainerLinkActive: false })}
                  onMouseLeave={() => this.setState({ isContainerLinkActive: true })}
                >
                  Balkan...Market
                </Link>
              </div>
            </div>
          </div>
          <Link
            className={`product-preview__image ${imageSize}`}
            to={getProductUrl(slug.current)}
            aria-label={ariaLabel}
          >
            {fluidProps && <Img fluid={fluidProps} alt={previewImageAltText} />}
          </Link>
        </div>
      )
    }
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    document.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount() {
    document.addEventListener('scroll', this.onScroll)
  }
}

export default ProductPreview
